
const validatorHandler = {
  required: value => ({ result: !!value, message: 'Campo obligatorio' }),
  string: value => ({ result: isNaN(value), message: 'El campo ha de contener texto' }),
  phone: value => ({
    result: !isNaN(value) && value.replace(/ /g, '').toString().length >= 9,
    message: 'Teléfono no válido'
  }),
  email: value => ({
    result: value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/),
    message: 'Email no válido'
  }),
  cp: value => ({ result: !isNaN(value) && value.toString().length === 5, message: 'Código postal no válido' }),
  file: value => ({ result: validateFile(value,false), message: 'Archivo no válido' }),
  image: value => ({ result: validateFile(value,true), message: 'Imagen no válida' }),
  password: value => ({
    result: value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$/),
    message: 'Mínimo 6 carácteres, al menos un número, una letra mayúscula y una minúscula'
  }),
  dni_nie: value => ({
    result: value.match(/^[XYZ]?([0-9]{7,8})([A-Z])$/i),
    message: 'DNI/NIE no válido'
  }),
  brand:  value => ({
    result: !!value,
    message: 'Elige una marca'
  }),
};




/**
 * Valida un archivo en base a ciertos criterios. Lo usamos para el drag and drop de archivos de los formularios.
 * @param {File} file - El archivo a ser validado.
 * @returns {boolean} - True si es válido, False si no lo es.
 */
const validateFile = (file, onlyImg: boolean = false) => {
  let allowedTypes;
  // Verifica el tipo de archivo permitido
  if(onlyImg){
    allowedTypes = ['image/jpeg', 'image/png'];
  }else{
    allowedTypes = ['image/jpeg',
      'image/png', 
      'image/gif', 
      'application/pdf', 
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];
  }

  if(file=='' && onlyImg){
    return true;
  }

  if (!allowedTypes.includes(file.type)) {
    return false;
  }

  // Verifica el tamaño del archivo (1 MB = 1024 * 1024 bytes)
  const maxSizeInBytes = 1024**2;
  if (file.size > maxSizeInBytes) {
    return false;
  }

  // Si la validación pasa, devuelve true
  return true;
};


export default validatorHandler;