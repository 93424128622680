import validatorHandler from '~/helpers/validators';


export default function useFormsMa ( fields:Object ){

  /**
  * Inicialización
  */
  const formData = reactive<Object>({});
  const formStates = reactive<Object>({});

  let claves = Object.keys(fields);

  for(const clave of claves){
    formData[clave] = fields[clave].value;
    formStates[clave] = false;
  }

  const removeKey = (key: String) => {
    claves=claves.filter(el=> el !== key);

    if(key in formData){
      delete formData[key];
    }
    if(key in formStates){
      delete formStates[key];
    }

  };

  const addKey = (key: String) => {
    if(!(claves.includes(key))){
      claves.push(key)
    }
    if(!(key in formData)){
      formData[key] = fields[key].value;
    }
    if(!(key in formStates)){
      formStates[key] = false;
    }

  };
  /**
   * 
   * Valida los datos de formData y carga formStates en caso de errores
   */
  const validateData = ()=> {
    let isOk = true;

    for(const clave of claves){

      formStates[clave] = false;

      for(let x = 0; x < fields[clave].validations.length; x++ ){
        const validation: string= fields[clave].validations[x];
        const {result,message} = validatorHandler[validation](formData[clave]);
        if(!result){
          formStates[clave] = message;
          isOk = false;
          break;
        }
      }

    }


    const fData = new FormData();

    if(isOk){
      for(const clave of claves){
        if (clave == 'acepta_gdpr_1' || clave == 'acepta_gdpr_2') {
          fData.append(clave, Number(formData[clave]));
        } else {
          fData.append(clave, formData[clave]);
        }
      }
    }
    return {isOk,formData:fData};
    
  };



  return {formData, formStates, addKey,removeKey, validateData};

}